<script lang="ts" context="module">
  export interface EventHeaderProps {
    event: EventEntry;
  }
</script>

<script lang="ts">
  import LayeredSection from "../layout/LayeredSection.svelte";
  import Buttons from "../buttons/Buttons.svelte";
  import Icon from "@elements/Icon.svelte";
  import HeroTitle from "../HeroTitle.svelte";
  import { convertStatamicAssetToCDN, getForMenOrWomen } from "@utils/general";
  import type { EventEntry } from "../../types/entries";
  import { isBackgroundPlayerPaused } from "../../stores/playerStore.ts";
  import { openIFrameModal } from "../../stores/modalStore.ts";
  import Block from "@components/blocks/Block.svelte";
  import Blocks from "@components/blocks/Blocks.svelte";

  let video: HTMLVideoElement;
  $: video && ($isBackgroundPlayerPaused ? video.pause() : video.play());

  export let event: EventEntry;

  let buttons = event.buttons.map((button) => ({
    leftIcon: { name: button.left_icon.icon.key },
    text: button.text,
    href: button.href,
    variant: button.variant.key,
  }));
</script>

<LayeredSection
  bind:video
  addClasses="box-border flex min-h-screen items-center justify-start overflow-hidden bg-cover bg-center"
  layers={[
    {
      backgroundVideo: {
        videoUrl: event.background_video_url,
        imageUrl:
          convertStatamicAssetToCDN(
            event?.background_video_fallback_image?.id,
          ) || "",
      },
    },
    {
      backgroundCSS:
        "background-blend-mode: normal, normal, color, color-dodge, normal; background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 65.62%, #010101 100%), linear-gradient(0deg, rgba(1, 1, 1, 0.10) 0%, rgba(1, 1, 1, 0.10) 100%);",
    },
  ]}
>
  <div
    class="@container pt-48 pb-24 container w-full z-10 text-white gap-6 md:gap-x-24 flex flex-col md:grid grid-cols-2"
  >
    <div class="row-start-1">
      <p class="text-p100 mb-4 text-h6">
        {event.leader_text || "Events " + getForMenOrWomen(event)}
      </p>
      <HeroTitle data={event.hero_title} />

      {#if event.blocks.length > 1}
        <Blocks blocks={event.blocks} />
      {/if}
      {#if event.hero_subtitle.length}
        <div class="text-hero2 font-thin text-left max-w-screen-md">
          {#each event.hero_subtitle as item}
            <Block block={item} />
          {/each}
        </div>
      {/if}
    </div>
    {#if event.featured_video_fallback_image}
      <div class="self-center row-start-2 md:col-start-2 md:row-start-auto">
        <div class="relative aspect-video shadow-md w-full">
          <img
            alt=""
            class="object-fill"
            src={convertStatamicAssetToCDN(
              event.featured_video_fallback_image?.id,
            )}
          />
          {#if event.featured_video_url}
            <button
              class="absolute top-1/2 left-1/2 transition-all duration-200 opacity-80 hover:opacity-100 hover:scale-110 fill-white transform -translate-x-1/2 -translate-y-1/2 fill-n50"
              on:click={() =>
                event.featured_video_url &&
                openIFrameModal({
                  src: event.featured_video_url,
                  title: event.title,
                })}
            >
              <Icon
                name="play"
                size="100px"
                class="fill-white drop-shadow-lg"
              />
            </button>
          {/if}
        </div>
      </div>
    {/if}
    <div class="row-start-3 md:row-start-2">
      <Buttons position="start" {buttons} />
    </div>
  </div></LayeredSection
>
